import { interpolate } from '.';

/**
 * Returns a reduced array of meta tags based on a passed in array of meta data for a page
 * @param {array} pageMeta An array of meta objects used to generate meta tags
 * @param {function} imageToSrc A function that generates a valid src value for an image url
 */
export default function generateCMSMetaTags(pageMeta, imageToSrc) {
  return pageMeta.reduce((roster, { content, description, image, name, provider, title, type }) => {
    if (type === 'social') {
      const descriptionName = `${provider}:description`;
      const titleName = `${provider}:title`;
      const imageName = `${provider}:image`;
      const imageValue = imageToSrc(image);
      const isOpenGraph = provider === 'og';

      if (isOpenGraph) {
        return roster.concat(
          <meta
            key={descriptionName}
            property={descriptionName}
            content={interpolate(description)}
          />,
          <meta
            key={titleName}
            property={titleName}
            content={interpolate(title)}
          />,
          <meta
            key={imageName}
            property={imageName}
            content={imageValue}
          />,
        );
      }

      return roster.concat(
        <meta
          key={descriptionName}
          name={descriptionName}
          content={interpolate(description)}
        />,
        <meta
          key={titleName}
          name={titleName}
          content={interpolate(title)}
        />,
        <meta
          key={imageName}
          name={imageName}
          content={imageValue}
        />,
      );
    }

    return roster.concat(
      <meta key={name} name={name} content={interpolate(content)} />,
    );
  }, []);
}
